import { isVNode, h, render } from 'vue'
import { initFlowbite } from 'flowbite'
// @ts-ignore
import toastComponent, { ToastProps } from './ToastComponent.vue'

let timer: any = null

export const toast = {
  open: (options: ToastProps) => {
    clearInterval(timer)
    remove()
    createToast(options)
    initFlowbite()
    countdown()
  },
  close: () => {
    remove()
  },
}

function countdown() {
  timer = setTimeout(() => {
    remove()
  }, 10000)
}

function remove() {
  const toast = document.getElementById('zenni-toast')
  if (toast) {
    document.body.removeChild(toast)
  }
}

function createToast(options: ToastProps) {
  const instance = createToastByOpts(options)
  addToBody(instance)
  return instance?.proxy
}
function createToastByOpts(opts: any) {
  if (isVNode(opts.message)) {
    return createComponent(toastComponent, opts, () => opts.message)
  }
  return createComponent(toastComponent, opts)
}

function createComponent(Component: any, props: any, children: any = null) {
  const vnode: any = h(Component, { ...props, ref: 'el_component' }, children)
  const container = document.createElement('div')
  vnode[Symbol('el_component_container')] = container
  render(vnode, container)
  return vnode.component
}
function addToBody(instance: any) {
  document.body.appendChild(instance.vnode.el)
}
